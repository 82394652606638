import { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import PageLoading from '~/components/loading/web-page';

import Footer from 'web~/components/footer';

const Lessor = lazy(() => import(/* webpackChunkName: "lessor-page" */ '../views/lessor')); // 임대인
const Lessee = lazy(() => import(/* webpackChunkName: "lessee-page" */ '../views/lessee')); // 임차인
const ContractInquiry = lazy(() => import(/* webpackChunkName: "contract-inquiry-page" */ '../views/contract-inquiry')); // 계약조회
const Verification = lazy(() => import(/* webpackChunkName: "verification-page" */ '../views/verification')); // 진위확인
const Intro = lazy(() => import(/* webpackChunkName: "intro-page" */ '../views/intro')); // 소개
const Home = lazy(() => import(/* webpackChunkName: "home-page" */ '../views/home')); // 홈

function Routes() {
  return (
    <div id="root-page" style={{ minWidth: '1200px', position: 'relative' }}>
      <Suspense fallback={<PageLoading />}>
        <Switch>
          {/* 임대인 */}
          <Route path="/lessor">
            <Lessor />
          </Route>

          {/* 임차인 */}
          <Route path="/lessee">
            <Lessee />
          </Route>

          {/* 계약조회 */}
          <Route path="/contract-inquiry/:tab(lessor|lessee)">
            <ContractInquiry />
          </Route>

          {/* 진위확인 */}
          <Route exact path="/verification">
            <Verification />
          </Route>

          {/* 소개 */}
          <Route path="/intro">
            <Intro />
          </Route>

          {/* 홈 */}
          <Route exact path="/">
            <Home />
          </Route>

          <Redirect to="/" />
        </Switch>
      </Suspense>

      <Footer />
    </div>
  );
}

export default Routes;
