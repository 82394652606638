import { createGlobalStyle, css } from 'styled-components';
import reset from 'styled-reset';
import isMobile from '~/utils/is-mobile';

interface FontData {
  name: string;
  weights: [number, string][];
  src: string;
}

/**
 * weights
 * [700, 'Bold']
 * [500, 'Medium']
 * [400, 'Regular']
 * [300, 'Light']
 * [100, 'Thin']
 */
const fontData: FontData = {
  name: 'Spoqa Han Sans Neo',
  weights: [[700, 'Bold'], [500, 'Medium'], [400, 'Regular']],
  src: '//static.dabangapp.com/font/SpoqaHanSansNeo'
};

const createFontFace = (font: FontData) => {
  return font.weights.map(([weight, weightStr]) => {
    return css`
      @font-face {
        font-family: ${font.name};
        font-weight: ${weight};
        src: ${`local('${font.name} ${weightStr}')`},
          url('${font.src}-${weightStr}.woff2') format('woff2'),
          url('${font.src}-${weightStr}.woff') format('woff'),
          url('${font.src}-${weightStr}.ttf') format('truetype')
      }
    `;
  });
}

export default createGlobalStyle`
  ${reset};
  
  ${createFontFace(fontData)}

  * {
    box-sizing: border-box;
  }

  html,
  body {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Spoqa Han Sans Neo', -apple-system, 'sans-serif' !important;
    overscroll-behavior-y: contain;
    position: fixed;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    flex-grow: 1;
    font-size: 14px;
    font-weight: 400;
  }

  #root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: contain;
  }

  #root-page {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  #root {
    ${!isMobile() && css`
      scrollbar-width: thin;
      
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: green;
         background-color: ${({ theme }) => theme.color.gray[200]};
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${({ theme }) => theme.color.gray[900]};
        background-clip: content-box;
      }
    `}
  }

  [data-modal='init'] {
    transition: background-color 400ms ease-in-out;
    background-color: rgba(0, 0, 0, 0);
    
    ${!isMobile() && css`
      scrollbar-width: thin;
      
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: green;
         background-color: ${({ theme }) => theme.color.gray[800]};
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${({ theme }) => theme.color.gray[100]};
        background-clip: content-box;
      }
    `}
  }
  
  u {
    text-decoration: underline;
  }

  input,
  textarea,
  button,
  select,
  a,
  p {
    -webkit-tap-highlight-color: transparent;

    &:disabled {
      opacity: 1;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    outline: none;
    touch-action: manipulation;
  }

  input, button {
    &:focus,
    &:active {
      outline: none;
    }
  }

  input, textarea {
    -webkit-appearance: none;
  }

  button {
    margin: 0;
    outline: none;
    border-radius: 0;
    font-family: 'Spoqa Han Sans Neo', -apple-system, sans-serif !important;
  }

  input, textarea {
    user-select: text;

    &::-webkit-input-placeholder {
      color: ${({ theme }) => theme.color.gray[600]};
    }
    &:-ms-input-placeholder {
      color: ${({ theme }) => theme.color.gray[600]};
    }
    &::placeholder {
      color: ${({ theme }) => theme.color.gray[600]};
    }
    &:disabled::-webkit-input-placeholder {
      color: ${({ theme }) => theme.color.gray[500]};
    }
    &:disabled:-ms-input-placeholder {
      color: ${({ theme }) => theme.color.gray[500]};
    }
    &:disabled::placeholder {
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }

  select, input, textarea {
    outline: none;
    font-family: 'Spoqa Han Sans Neo', -apple-system, sans-serif !important;
  }
  
  h1, h2, h3, h4, h5, h6, p, a, i, pre, span, li, text, th, td, label {
    font-family: 'Spoqa Han Sans Neo', -apple-system, sans-serif !important;
    text-shadow: 0 0 1px rgba(0,0,0,0.1);
    text-size-adjust: none;
    font-size-adjust: none;
  }

  strong, b {
    font-weight: 700;
    font-family: 'Spoqa Han Sans Neo', -apple-system, sans-serif !important;
  }
  
  mark {
    color: ${({ theme }) => theme.color.blue[500]};
    background-color: transparent;
  }
`;
