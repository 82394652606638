// 회사소개
export const companySite = 'https://www.station3.co.kr';

// 전자계약 관리규정
export const managementRegulations = 'https://static.dabangapp.com/terms/dabang-sign/sign_termsofcontract.html';

// 개인정보처리방침
export const personalTerms = 'https://pro.dabangapp.com/terms/4';

// 모바일 헤더 높이
export const mobileHeaderHeight = 64;


// naver blog
export const naverBlogUrl = 'https://blog.naver.com/station3inc';
// naver post
export const naverPostUrl = 'https://post.naver.com/station3inc';
// facebook
export const facebookUrl = 'https://www.facebook.com/dabangapp';
// instagram
export const instagramUrl = 'https://www.instagram.com/dabang_app';
// youtube
export const youtubeUrl = 'https://www.youtube.com/channel/UCAZNxUDChQXqce0N4ibllcw/featured';

// api url
export const devApiUrl = '//test-dabang-contract-api.dabangapp.com';
export const prodApiUrl = '//api-sign.dabangapp.com';
export const baseApiUrl = process.env.REACT_APP_BUILD === 'dev' ? devApiUrl : prodApiUrl;

// banner url
export const hana1BlogUrl = 'http://naver.me/FiLDdjTo';
export const hana1SiteUrl = 'https://m.kebhana.com/cont/hidden/html/2021/dabang_LoanInfo.html';

// 입주안내문
// 정부24
export const gov24 = 'https://www.gov.kr/portal/main';
// 인터넷 등기소
export const internetRegistrationOffice = 'http://www.iros.go.kr/pos1/jsp/help2/jsp/main.jsp';
