import { css, FlattenSimpleInterpolation } from 'styled-components';
import font2, { ThemeFont2 } from './font';
import color, { ThemeColor } from './color';

export interface Theme {
  color: ThemeColor;
  font: ThemeFont;
  font2: ThemeFont2;
}

export interface ThemeFont {
  h1: FlattenSimpleInterpolation;
  h2: FlattenSimpleInterpolation;
  h3: FlattenSimpleInterpolation;
  subtitle1: FlattenSimpleInterpolation;
  subtitle2: FlattenSimpleInterpolation;
  body1: FlattenSimpleInterpolation;
  body2: FlattenSimpleInterpolation;
  body3: FlattenSimpleInterpolation;
  caption1: FlattenSimpleInterpolation;
  caption2: FlattenSimpleInterpolation;
}
export type TThemeFontName = keyof ThemeFont;

const font = {
  h1: css`
    font-size: 46px;
    line-height: 70px;
    letter-spacing: -1px;
  `,
  h2: css`
    font-size: 38px;
    line-height: 60px;
    letter-spacing: -1px;
  `,
  h3: css`
    font-size: 30px;
    line-height: 48px;
    letter-spacing: -0.5px;
  `,
  subtitle1: css`
    font-size: 24px;
    line-height: 38px;
    letter-spacing: -0.5px;
  `,
  subtitle2: css`
    font-size: 20px;
    line-height: 34px;
    letter-spacing: -0.5px;
  `,
  body1: css`
    font-size: 18px;
    line-height: 30px;
  `,
  body2: css`
    font-size: 16px;
    line-height: 26px;
  `,
  body3: css`
    font-size: 14px;
    line-height: 24px;
  `,
  caption1: css`
    font-size: 12px;
    line-height: 20px;
  `,
  caption2: css`
    font-size: 10px;
    line-height: 16px;
  `,
} as ThemeFont;


const theme = {
  color,
  font,
  font2,
};

export default theme;
