import { css, FlattenSimpleInterpolation } from 'styled-components';

type TFont = {
  regular: FlattenSimpleInterpolation;
  medium: FlattenSimpleInterpolation;
  bold: FlattenSimpleInterpolation;
};

export interface ThemeFont2 {
  h1: TFont;
  h2: TFont;
  h3: TFont;
  h4: TFont;
  subtitle1: TFont;
  subtitle2: TFont;
  body1: TFont;
  body2: TFont;
  body3: TFont;
  caption1: TFont;
  caption2: TFont;
}

export type TThemeFontName2 = keyof ThemeFont2;

const h1 = css`
  font-size: 46px;
  line-height: 70px;
  letter-spacing: -1px;

  @media only screen and (max-width: 320px) {
    font-size: 44px;
  }
`;
const h2 = css`
  font-size: 38px;
  line-height: 60px;
  letter-spacing: -1px;
  
  @media only screen and (max-width: 320px) {
    font-size: 36px;
  }
`;
const h3 = css`
  font-size: 30px;
  line-height: 48px;
  letter-spacing: -0.5px;

  @media only screen and (max-width: 320px) {
    font-size: 28px;
  }
`;
const h4 = css`
  font-size: 24px;
  line-height: 38px;
  letter-spacing: -0.5px;

  @media only screen and (max-width: 320px) {
    font-size: 22px;
  }
`;
const subtitle1 = css`
  font-size: 22px;
  line-height: 38px;
  letter-spacing: -0.5px;

  @media only screen and (max-width: 320px) {
    font-size: 20px;
  }
`;
const subtitle2 = css`
  font-size: 20px;
  line-height: 32px;

  @media only screen and (max-width: 320px) {
    font-size: 18px;
  }
`;
const body1 = css`
  font-size: 18px;
  line-height: 30px;

  @media only screen and (max-width: 320px) {
    font-size: 17px;
  }
`;
const body2 = css`
  font-size: 16px;
  line-height: 26px;
  
  @media only screen and (max-width: 320px) {
    font-size: 15px;
  }
`;
const body3 = css`
  font-size: 14px;
  line-height: 24px;

  @media only screen and (max-width: 320px) {
    font-size: 13px;
  }
`;
const caption1 = css`
  font-size: 12px;
  line-height: 20px;

  @media only screen and (max-width: 320px) {
    font-size: 11px;
  }
`;
const caption2 = css`
  font-size: 10px;
  line-height: 16px;

  @media only screen and (max-width: 320px) {
    font-size: 9px;
  }
`;

const regular = css`
  font-weight: 400;
`;
const medium = css`
  font-weight: 500;
`;
const bold = css`
  font-weight: 700;
`;

const font: ThemeFont2 = {
  h1: {
    regular: css`
      ${h1};
      ${regular};
    `,
    medium: css`
      ${h1};
      ${medium};
    `,
    bold: css`
      ${h1};
      ${bold};
    `,
  },
  h2: {
    regular: css`
      ${h2};
      ${regular};
    `,
    medium: css`
      ${h2};
      ${medium};
    `,
    bold: css`
      ${h2};
      ${bold};
    `,
  },
  h3: {
    regular: css`
      ${h3};
      ${regular};
    `,
    medium: css`
      ${h3};
      ${medium};
    `,
    bold: css`
      ${h3};
      ${bold};
    `,
  },
  h4: {
    regular: css`
      ${h4};
      ${regular};
    `,
    medium: css`
      ${h4};
      ${medium};
    `,
    bold: css`
      ${h4};
      ${bold};
    `,
  },
  subtitle1: {
    regular: css`
      ${subtitle1};
      ${regular};
    `,
    medium: css`
      ${subtitle1};
      ${medium};
    `,
    bold: css`
      ${subtitle1};
      ${bold};
    `,
  },
  subtitle2: {
    regular: css`
      ${subtitle2};
      ${regular};
    `,
    medium: css`
      ${subtitle2};
      ${medium};
    `,
    bold: css`
      ${subtitle2};
      ${bold};
    `,
  },
  body1: {
    regular: css`
      ${body1};
      ${regular};
    `,
    medium: css`
      ${body1};
      ${medium};
    `,
    bold: css`
      ${body1};
      ${bold};
    `,
  },
  body2: {
    regular: css`
      ${body2};
      ${regular};
    `,
    medium: css`
      ${body2};
      ${medium};
    `,
    bold: css`
      ${body2};
      ${bold};
    `,
  },
  body3: {
    regular: css`
      ${body3};
      ${regular};
    `,
    medium: css`
      ${body3};
      ${medium};
    `,
    bold: css`
      ${body3};
      ${bold};
    `,
  },
  caption1: {
    regular: css`
      ${caption1};
      ${regular};
    `,
    medium: css`
      ${caption1};
      ${medium};
    `,
    bold: css`
      ${caption1};
      ${bold};
    `,
  },
  caption2: {
    regular: css`
      ${caption2};
      ${regular};
    `,
    medium: css`
      ${caption2};
      ${medium};
    `,
    bold: css`
      ${caption2};
      ${bold};
    `,
  },
};

export default font;
