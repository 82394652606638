import styled from 'styled-components';
import { layout } from '~/styled/layout';

import ksImg from './images/img-ks.png';

export const TermsWrap = styled.div`
  ${layout};
  flex: none;
  height: 70px;
  margin-top: auto;
  border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
  
  > div {
    display: flex;
    align-items: center;
    position: relative;
  }
`;

export const Footer = styled.footer`
  ${layout}
  flex: none;
  border-top: 1px solid ${({ theme }) => theme.color.gray[200]};
  
  > div {
    display: flex;
    align-items: flex-start;
    padding: 32px 0 60px;
  }
`;

export const InfoWrap = styled.div`
  flex-grow: 1;
`;

export const CompanyName = styled.div`
  display: flex;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.color.gray[600]};
  ${({ theme }) => theme.font2.caption1.regular};

  > h1 {
    flex: none;
    margin-right: 8px;
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font2.caption1.bold};
  }

  > b {
    flex: none;
    margin-left: 4px;
    ${({ theme }) => theme.font2.caption1.bold};
  }
`;

export const CompanyInfo = styled.p`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.gray[600]};
  ${({ theme }) => theme.font2.caption1.regular};

  > span {
    flex: none;
    display: block;
    width: 1px;
    height: 14px;
    margin: 0 8px;
    background-color: ${({ theme }) => theme.color.gray[200]};
    position: relative;
    bottom: 1px;
  }

  > b {
    flex: none;
    margin-left: 4px;
    ${({ theme }) => theme.font2.caption1.bold};
  }

  & + & {
    margin-top: 8px;
  }
`;

export const Award = styled.div`
  display: flex;
  align-items: center;
  margin-top: 34px;

  > p {
    color: ${({ theme }) => theme.color.gray[600]};
    ${({ theme }) => theme.font2.caption1.medium};
  }
`;

export const KsImg = styled.div`
  width: 32px;
  height: 24px;
  margin-right: 8px;
  background: url(${ksImg}) center center no-repeat;
  background-size: cover;
`;

export const ServiceWrap = styled.div`
  flex: none;
  padding-right: 7px;

  > h1 {
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font2.caption1.bold};
  }

  > p {
    color: ${({ theme }) => theme.color.gray[600]};
    ${({ theme }) => theme.font2.caption1.medium};
  }
`;

export const TelWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1px;

  > svg {
    flex: none;
  }
    
  > h2 {
    color: ${({ theme }) => theme.color.blue[500]};
    ${({ theme }) => theme.font2.h3.bold};
  }
`;

export const SnsWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  > a + a {
    flex: none;
    margin-left: 16px;
  }
`;

export const OpenLink = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer noopener',
})`

  &:hover {
    > svg {
      circle {
        fill: ${({ theme }) => theme.color.gray[50]} !important;
      }
      path {
        fill: ${({ theme }) => theme.color.gray[900]} !important;
      }
    }
  }
`;
