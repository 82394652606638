import { StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { enableES5 } from 'immer';

import theme from '~/theme';
import reportWebVitals from '~/reportWebVitals';
import GlobalStyle from '~/styled';

import ModalProvider from '~/context/modal-context';
import Routes from 'web~/routes';

enableES5();

render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ModalProvider>
        <Router>
          <Routes />
        </Router>
      </ModalProvider>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
