import { useState, useContext, createContext, ReactNode } from 'react';
import produce from 'immer';

interface Context {
  modals: string[];
  pushModal(modalId: string): void;
  removeModal(): void;
}

const ModalContext = createContext<Context>({
  modals: [],
  pushModal() {},
  removeModal() {},
});

interface Props {
  children: ReactNode;
}

function ModalProvider({
  children,
}: Props) {
  const [modals, setModals] = useState<string[]>([]);

  function pushModal(modalId: string) {
    const nextModals = produce(modals, draft => {
      draft.push(modalId);
    });
    setModals(nextModals);
  }

  function removeModal() {
    const nextModals = produce(modals, draft => {
      draft.pop();
    });
    setModals(nextModals);
  }

  return (
    <ModalContext.Provider
      value={{
        modals,
        pushModal,
        removeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export function useRootModal() {
  return useContext(ModalContext);
}

export default ModalProvider;
