import styled from 'styled-components';

export const FamilySiteList = styled.ul<{ isOpen: boolean; }>`
  flex: none;
  width: 298px;
  height: ${({ isOpen }) => isOpen ? '255px' : '69px'};
  position: absolute;
  right: 0;
  bottom: 1px;
`;

export const DefaultItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 69px;
  padding: 0 24px;
  margin-top: 1px;
  border-left: 1px solid ${({ theme }) => theme.color.gray[100]};
  border-right: 1px solid ${({ theme }) => theme.color.gray[100]};
  cursor: pointer;
  user-select: none;

  > p {
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font2.caption1.medium};
  }
`;

export const LinkItem = styled.li`
  height: 62px;
  background-color: ${({ theme }) => theme.color.white};

  > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 24px;
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font2.caption1.medium};
    border: 1px solid ${({ theme }) => theme.color.gray[200]};
    border-bottom: 0;
    user-select: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.gray[50]};
    
    > a {
      text-decoration: underline;
    }
  }
`;