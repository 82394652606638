import { css } from 'styled-components';

export const mobileMaxWidth = css`
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
`;

export const layout = css`
  flex-grow: 1;
  width: 100%;

  > div {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
  }
`;

export const mobileLayout = css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
  background-color: ${({ theme }) => theme.color.gray['100']};
  user-select: none;

  > div {
    ${mobileMaxWidth};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;
