import { Link } from 'react-router-dom';
import { companySite, managementRegulations, personalTerms } from '~/enum';

import { LinkList } from './styled';

function LinksComponent() {
  return (
    <LinkList>
      <li>
        <a
          href={companySite}
          target="_blank"
          rel="noreferrer noopener"
        >
          회사소개
        </a>
      </li>
      <li>
        <Link to="/intro/legal-effect">
          법적효력 및 보안
        </Link>
      </li>
      <li>
        <a
          href={managementRegulations}
          target="_blank"
          rel="noreferrer noopener"
        >
          비대면계약관리규정
        </a>
      </li>

      <li>
        <a
          href={personalTerms}
          target="_blank"
          rel="noreferrer noopener"
        >
          <b>개인정보처리방침</b>
        </a>
      </li>
    </LinkList>
  );
}

export default LinksComponent;
