import { useRef, useState, useEffect, useMemo, FC, SVGProps } from 'react';
import { TSvgIconName } from '~/@types/svg-icon';

function useDynamicSVGImport(name: TSvgIconName) {
  const _mount = useRef(true);
  const [prevName, setPrevName] = useState('');
  const [loading, setLoading] = useState(false);
  const [Icon, setIcon] = useState<FC<SVGProps<SVGSVGElement>>>();

  useEffect(() => {
    _mount.current = true;

    return () => {
      _mount.current = false;
    };
  }, []);

  useEffect(() => {
    setLoading(true);

    const importIcon = async () => {
      if (name === prevName) return;

      try {
        const { default: namedImport } = await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!./images/${name}.svg`);

        if (!_mount.current) return;

        setIcon(namedImport);
        setPrevName(name);
        
      } catch(err) {
        throw err;
  
      } finally {
        if (!_mount.current) return;
        setLoading(false);
      }
    };

    importIcon();
  }, [name]);

  return { loading, Icon };
}

interface Props extends SVGProps<SVGSVGElement> {
  name: TSvgIconName;
}

/**
 * TSvgIconName 타입은 `yarn make-svg-icon-type` 명령어를 사용해 생성할수있다.
 * 
 * [필수] svg 이미지가 추가될때마다 위 명령어를 실행해야 한다.
 */
function SvgIconComponent({
  name, ...props
}: Props) {
  const { loading, Icon } = useDynamicSVGImport(name);
  
  return useMemo(() => {
    if (loading) return null;
    
    if (Icon) {
      return <Icon {...props} style={{ flex: 'none' }} />;
    }
    
    return null;
  }, [Icon, loading]);
}

export default SvgIconComponent;
