import { WebPage, Svg } from './styled';

function WebRouteLoadingComponent() {
  return (
    <WebPage>
      <div>
        <Svg width="40" height="40" viewBox="0 0 40 40">
          <polygon points="0 0 0 40 40 40 40 0" />
        </Svg>
      </div>
    </WebPage>
  );
}

export default WebRouteLoadingComponent;
