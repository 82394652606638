import { facebookUrl, instagramUrl, youtubeUrl, naverBlogUrl, naverPostUrl } from '~/enum';
import SvgIcon from '~/components/svg-icon';

import Links from './components/links';
import FamilySite from './components/family-site';

import {
  TermsWrap,
  Footer, InfoWrap, CompanyName, CompanyInfo, Award, KsImg,
  ServiceWrap, TelWrap, SnsWrap, OpenLink,
} from './styled';

function FooterComponent() {
  return (
    <>
      <TermsWrap>
        <div>
          <Links />
          <FamilySite />
        </div>
      </TermsWrap>

      <Footer>
        <div>
          <InfoWrap>
            <CompanyName>
              <h1>스테이션3</h1>
              대표<b>유형석</b>
            </CompanyName>
    
            <CompanyInfo>
              사업자 번호 <b>220-88-59156</b>
              <span />
              통신판매업신고번호 <b>제2013-서울 강남-02884호</b>
              <span />
              주소 <b>서울시 서초구 서초대로 301 동익 성봉빌딩 10층</b>
            </CompanyInfo>
            
            <CompanyInfo>
              Station3, Inc. All rights reserved.
            </CompanyInfo>

            <Award>
              <KsImg />
              <p>2019 한국서비스품질지수 모바일 중개앱 1위 선정</p>
            </Award>
          </InfoWrap>

          <ServiceWrap>
            <h1>고객센터</h1>
            <TelWrap>
              <SvgIcon name="ic_32_call_blue500" />
              <h2>1899-6840</h2>
            </TelWrap>
            <p>평일 10:00 ~ 18:30 토, 일요일, 공휴일 휴무</p>

            <SnsWrap>
              <OpenLink href={facebookUrl}>
                <SvgIcon name="ic_32_sns_facebook_enabled" />
              </OpenLink>
              <OpenLink href={instagramUrl}>
                <SvgIcon name="ic_32_sns_instagram_enabled" />
              </OpenLink>
              <OpenLink href={youtubeUrl}>
                <SvgIcon name="ic_32_sns_youtube_enabled" />
              </OpenLink>
              <OpenLink href={naverBlogUrl}>
                <SvgIcon name="ic_32_sns_blog_01_enabled" />
              </OpenLink>
              <OpenLink href={naverPostUrl}>
                <SvgIcon name="ic_32_sns_blog_02_enabled" />
              </OpenLink>
            </SnsWrap>
          </ServiceWrap>
        </div>
      </Footer>
    </>
  );
}

export default FooterComponent;
