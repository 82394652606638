import styled, { keyframes } from 'styled-components';
import { layout } from '~/styled/layout';

export const WebPage = styled.div`
  ${layout};
  flex-grow: 1;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MobilePage = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 425px;
  margin: 0 auto;
  padding: 50px 0 100px;
  background-color: ${({ theme }) => theme.color.white};
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const movedash = keyframes`
	to {
    stroke-dashoffset: 250%;
  }
`;

export const Svg = styled.svg`
  flex: none;
  
  polygon {
    fill: none;
    stroke-width: 8px;
    stroke: ${({ theme }) => theme.color.blue[500]};
    stroke-dasharray: 40;
    stroke-dashoffset: 50%;
    animation: ${movedash} 1.5s forwards 0s infinite;
  }

  & + p {
    margin-top: 16px;
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.color.gray[900]};
  ${({ theme }) => theme.font2.body3.regular};
`;
