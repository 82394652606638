import { useRef, useState, useCallback, useEffect } from 'react';

import SvgIcon from '~/components/svg-icon';

import { FamilySiteList, DefaultItem, LinkItem } from './styled';

function FamilySiteComponent() {
  const _dom = useRef<HTMLUListElement>(null);
  const [isOpen, setOpen] = useState(false);

  const mouseDown = useCallback(e => {
    if (_dom.current && !_dom.current.contains(e.target)) {
      setOpen(() => {
        document.removeEventListener('mousedown', mouseDown);
        return false;
      });
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', mouseDown, false);
    } else {
      document.removeEventListener('mousedown', mouseDown);
    }
  }, [isOpen]);

  function handleOpen() {
    setOpen(prev => !prev);
  }

  return (
    <FamilySiteList isOpen={isOpen} ref={_dom}>
      {isOpen && <>
        <LinkItem>
          <a
            href="https://dabangapp.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            다방
          </a>
        </LinkItem>
        <LinkItem>
          <a
            href="https://pro.dabangapp.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            다방프로
          </a>
        </LinkItem>
        <LinkItem>
          <a
            href="https://hub.dabangapp.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            다방허브
          </a>
        </LinkItem>
      </>}
      <DefaultItem onClick={handleOpen}>
        <p>Family Site</p>
        {isOpen ? (
          <SvgIcon name="ic_24_arrow_up_gray500" />
        ) : (
          <SvgIcon name="ic_24_arrow_down_gray500" />
        )}
      </DefaultItem>
    </FamilySiteList>
  );
}

export default FamilySiteComponent;
