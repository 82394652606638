import styled, { css } from 'styled-components';

export const LinkList = styled.ul`
  flex: none;
  display: flex;
  align-items: center;

  > li {
    flex: none;
    margin-right: 32px;
    
    > a {
      color: ${({ theme }) => theme.color.gray[900]};
      ${({ theme }) => theme.font2.caption1.medium};
      
      &.blue {
        color: ${({ theme }) => theme.color.blue[500]};
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
